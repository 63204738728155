import { Center, Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import { HeartIcon } from '../BaseIcon'
import { useRouter } from 'next/router'

interface IProp extends FlexProps {
  cardInfo: any
}

function Index({ cardInfo, ...props }: IProp) {
  const router = useRouter()
  return (
    <Flex
      flexDir="column"
      w={pv(160)}
      onClick={() => router.push(`/event?type=general&eventId=${cardInfo?.eventId}`)}
      {...props}
    >
      {/* img */}
      <Center pos="relative" mb={pv(4)}>
        <Image
          w={pv(160)}
          h={pv(160)}
          src={cardInfo?.eventTrendingImageUrl}
          borderRadius={pv(4)}
          objectFit={'cover'}
        />
        {/* New */}
        {cardInfo?.isNew && (
          <Center
            pos="absolute"
            bgColor="green.100"
            color="black.100"
            textStyle="12"
            p={pv(2)}
            top={pv(10)}
            left={pv(10)}
          >
            NEW
          </Center>
        )}
        {/* Like */}
        {cardInfo?.haveLike && (
          <Center pos="absolute" color="black.100" textStyle="12" bottom={pv(8)} right={pv(8)}>
            <HeartIcon
              bgGradient="none"
              bgColor="black.200"
              w={pv(24)}
              h={pv(24)}
              imgProps={{ w: pv(12), h: pv(10) }}
            />
          </Center>
        )}
      </Center>
      {/* name */}
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        textStyle="14"
        lineHeight={pv(18)}
        mb={pv(2)}
      >
        {cardInfo?.eventTitle}
      </Text>
      {/* date */}
      <Text color="green.100" textStyle="14" lineHeight={pv(18)}>
        {cardInfo?.date}
      </Text>
    </Flex>
  )
}

export default React.memo(Index)
