import Ajax from '@/utils/request'

const ajax = new Ajax()

// 获取banner数据
export const getEventBanner = {
  fetcher: () => ajax.get('/event/banner'),
  key: '/event/banner',
}

// 获取trending数据
export const getEventTrending = {
  fetcher: () => ajax.get('/event/trending'),
  key: '/event/trending',
}

// 获取featured数据
export const getEventFeatured = {
  fetcher: () => ajax.get('/event/featured'),
  key: '/event/featured',
}
