import { Text, Flex, FlexProps, Box, Image } from '@chakra-ui/react'
import React, { useState } from 'react'
import pv from '@/utils/px2vw'
import dropIcon from '@/assets/imgs/icons/dropIcon.png'

interface IProp extends FlexProps {
  dropList: any
  dropListKey: number
  setKey: any
}

function Index({ dropList, dropListKey, setKey }: IProp) {
  const [isShowDrop, setIsShowDrop] = useState(false)
  const getIndex = (index: number) => {
    setIsShowDrop(false)
    setKey(index)
  }
  return (
    <Box position="relative" fontSize={pv(16)} fontWeight={600}>
      <Flex
        w={pv(108)}
        p={pv(8)}
        alignItems="center"
        color="green.100"
        borderRadius={pv(8)}
        onClick={() => setIsShowDrop(!isShowDrop)}
        bg="var(--after-hours-moss-moss-2, linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #071612)"
      >
        <Text flex={1}>{dropList[dropListKey].title}</Text>
        <Image src={dropIcon} h={pv(8)} />
      </Flex>
      <Box
        display={isShowDrop ? 'block' : 'none'}
        pos="absolute"
        w={pv(108)}
        p={pv(8)}
        borderRadius={pv(8)}
        bg="var(--after-hours-moss-moss-2, linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), #071612)"
      >
        {dropList.map((item: any, index: number) => (
          <Box
            color={index == dropListKey ? '#36FFC0' : '#fff'}
            onClick={() => getIndex(index)}
            key={index}
            h={pv(20)}
            mt={pv(8)}
            _first={{ margin: 0 }}
          >
            {item.title}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default React.memo(Index)
