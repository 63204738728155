import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Flex, Text, Image, Box } from '@chakra-ui/react'
import pv from '@/utils/px2vw'
import useSWR from 'swr'
import { getEventFeatured } from '@/apis/explore'
import usdt from '@/assets/imgs/usdt.png'
import logo from '@/assets/imgs/logo.png'
// import { LocationIcon } from '@/components/BaseIcon'
import HorizontalPoster from '@/components/Explore/HorizontalPoster'
import NewTrending from '@/components/Explore/NewTrending'
import Collection from '@/components/Explore/Collection'
import HeaderDrop from '@/components/HeaderDrop'
import BigCard from '@/components/BigCard'
import dayjs from 'dayjs'
import { calculatePrice, returnWeekName } from '@/utils'
import globalStore from '@/stores/global'
import BigNumber from 'bignumber.js'

function App() {
  const [featuredList, setFeaturedList] = useState([])
  const [dropListKey, setDropListKey] = useState(0)

  const { tokenPrice } = globalStore()
  const dropList: any = [
    {
      title: 'Event',
      value: 0,
    },
    {
      title: 'Collection',
      value: 1,
    },
  ]
  const router = useRouter()
  const { data: getEventFeaturedData } = useSWR(
    getEventFeatured.key,
    () => getEventFeatured.fetcher(),
    {
      revalidateOnFocus: false,
    }
  )
  useEffect(() => {
    if (getEventFeaturedData) {
      setFeaturedList(getEventFeaturedData)
    }
  }, [getEventFeaturedData])

  return (
    <Flex flexDir="column" pos="relative">
      {/* search */}
      <Flex
        p={`${pv(16)} 0 ${pv(16)} 0 `}
        pos="fixed"
        top={0}
        left={0}
        zIndex={99}
        w="100%"
        bg="black.200"
        alignItems="center"
        flex={1}
      >
        {/* <BaseSearch
          flexProps={{ w: `calc(100% - ${})`, mr: pv(8) }}
          value={searchValue}
          onChange={(event) => setSearchValue(event?.target?.value)}
        /> */}
        <Image alt="logo" src={logo} ml={pv(16)} w={pv(310)} h={pv(40)} />
        <Flex pos="absolute" right={pv(16)}>
          <HeaderDrop
            dropListKey={dropListKey}
            dropList={dropList}
            setKey={(index: number) => setDropListKey(index)}
          />
        </Flex>
        {/* <LocationIcon /> */}
      </Flex>
      {/* type list */}
      {/* <TypeList /> */}
      {/* Horizontal Poster */}

      <Box>
        <Flex display={dropListKey == 0 ? 'block' : 'none'}>
          <Flex>
            <HorizontalPoster />
          </Flex>
          {/* New & Trending */}
          <Text mt={pv(24)} color="white.70" textStyle="12" fontWeight="700" lineHeight={pv(16)}>
            New & Trending
          </Text>
          <NewTrending />
          {/* Featured */}
          <Text mt={pv(24)} textStyle="20" fontWeight="700" lineHeight={pv(24)} mb={pv(8)}>
            Featured
          </Text>
          {featuredList?.map((item: any, index: number) => {
            return (
              <BigCard
                mb={pv(16)}
                key={index}
                cardInfo={{
                  ...item,
                  unit: usdt,
                  type: 'Concert',
                  tokenName: item?.lowestListing?.orderPricingMethod,
                  tokenNumber: item?.lowestListing
                    ? new BigNumber(item?.lowestListing?.orderItemPrice).div(1e18).toFixed(4)
                    : 0,
                  price:
                    item?.lowestListing && tokenPrice
                      ? calculatePrice(item?.lowestListing?.orderItemPrice, tokenPrice)
                      : 0,
                  img: item?.eventFeatureImageUrl,
                  name: item?.eventTitle,
                  date: `${returnWeekName(dayjs(item?.eventStartTime).day())}, ${dayjs(
                    item?.eventStartTime
                  ).format('MMM DD')}`,
                  addr: item?.eventLocation?.formattedEventAddress,
                }}
                onClick={() => router.push(`/event?type=general&eventId=${item?.eventId}`)}
              />
            )
          })}
          {/* Last Minute */}
          {/* <Text color="white.70" textStyle="12" fontWeight="700" lineHeight={pv(16)} mt={pv(16)}>
        Last Minute
      </Text>
      <LastMinute /> */}
          {/* New Shows This Month */}
          {/* <NewShows /> */}
          {/* Featured */}
          {/* <Text
        fontFamily="Fixture"
        textStyle="16"
        fontWeight="700"
        lineHeight={pv(24)}
        mb={pv(8)}
        mt={pv(16)}
      >
        Featured
      </Text>
      <BigCard
        cardInfo={{
          haveLike: true,
          unit: usdt,
          price: '0.46',
          img: bigCard2,
          type: 'Concert',
          name: 'Beyoncé: Renaissance Tour',
          date: 'Fri, May 5',
          addr: 'Madison Square Garden, NY',
        }}
        onClick={() => router.push('/event?type=tour')}
      /> */}
          {/* Just for you */}
          {/* <ForYou /> */}
        </Flex>
        <Flex display={dropListKey == 1 ? 'block' : 'none'}>
          <Collection />
        </Flex>
      </Box>
    </Flex>
  )
}

export default App
