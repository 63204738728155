import { Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { getEventTrending } from '@/apis/explore'
import pv from '@/utils/px2vw'
import MiniCard from '../MiniCard'
import { returnDate } from '@/utils'

function Index() {
  const [list, setList] = useState([])
  const { data: getEventTrendingData } = useSWR(
    getEventTrending.key,
    () => getEventTrending.fetcher(),
    {
      revalidateOnFocus: false,
    }
  )
  useEffect(() => {
    if (getEventTrendingData) {
      const list = getEventTrendingData?.map((item: any) => {
        return {
          ...item,
          date: returnDate(item?.eventStartTime),
          isNew: true,
        }
      })
      setList(list)
    }
  }, [getEventTrendingData])

  return (
    <Flex flexDir="column">
      <Flex justifyContent="space-between">
        <Text textStyle="20" lineHeight={pv(24)}>
          New Events This Month
        </Text>
        {/* <Text color="white.80" textDecor="underline" fontSize={pv(15)} lineHeight={pv(24)}>
          view all
        </Text> */}
      </Flex>
      {/* List */}
      <Flex
        overflowX="auto"
        w="full"
        mt={pv(8)}
        __css={{
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <Flex w="max-content">
          {list?.map((item: any, index: number) => (
            <MiniCard key={index} cardInfo={item} mr={index === list.length - 1 ? 0 : pv(8)} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default React.memo(Index)
