import { Flex, Image, Text, Box, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import topShot from '@/assets/imgs/collection/topShot.png'
import start from '@/assets/imgs/collection/start.png'
// import tag from '@/assets/imgs/collection/tag.png'
import lj from '@/assets/imgs/collection/lj.png'
import sc from '@/assets/imgs/collection/sc.png'
import ty from '@/assets/imgs/collection/ty.png'
import pg from '@/assets/imgs/collection/pg.png'
import strike from '@/assets/imgs/collection/strike.png'
import cm from '@/assets/imgs/collection/cm.png'
import rda from '@/assets/imgs/collection/rda.png'
import ar from '@/assets/imgs/collection/ar.png'
import ao from '@/assets/imgs/collection/ao.png'

function Index() {
  return (
    <Box pb={pv(16)}>
      <Image src={topShot} />
      <Grid mt={pv(20)} gap={pv(8)} templateColumns="repeat(2, 1fr)">
        <GridItem border="1px solid #36FFC0" p={pv(8)} borderRadius={pv(8)} pos="relative">
          <Image src={lj} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="green.100" textTransform="uppercase">
                LEGENDARY
              </Text>
              <Image src={start} h={pv(9)} ml={pv(4)} />
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              #/49
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            LEBRON JAMES
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$6,312 USD</Text>
          </Flex>
        </GridItem>
        <GridItem border="1px solid #36FFC0" p={pv(8)} borderRadius={pv(8)} pos="relative">
          {/* <Flex
            fontSize={pv(12)}
            alignItems="center"
            bg="green.100"
            p={pv(4)}
            lineHeight={pv(22)}
            color="black"
            fontWeight={500}
            pos="absolute"
            borderTopLeftRadius={pv(8)}
            borderBottomRightRadius={pv(8)}
            top={0}
            left={0}
          >
            <Image src={tag} height={pv(14)} mr={pv(4)} />
            Listed
          </Flex> */}
          <Image src={sc} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="green.100" textTransform="uppercase">
                LEGENDARY
              </Text>
              <Image src={start} h={pv(9)} ml={pv(4)} />
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              #/12
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            STEPH CURRY
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$5,305 USD</Text>
          </Flex>
        </GridItem>
        <GridItem border="1px solid #5703FF" p={pv(8)} borderRadius={pv(8)} pos="relative">
          <Image src={ty} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="#8C53FF" textTransform="uppercase">
                Rare
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              #/49
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            TRAE YOUNG
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$1,265 USD</Text>
          </Flex>
        </GridItem>
        <GridItem
          border="1px solid rgba(255, 255, 255, 0.60)"
          p={pv(8)}
          borderRadius={pv(8)}
          pos="relative"
        >
          <Image src={pg} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="rgba(255, 255, 255, 0.80)" textTransform="uppercase">
                Common
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              #/49
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            PAUL GEORGE
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$891 USD</Text>
          </Flex>
        </GridItem>
      </Grid>
      <Image src={strike} mt={pv(32)} height={pv(20)} />
      <Grid mt={pv(20)} gap={pv(8)} templateColumns="repeat(2, 1fr)">
        <GridItem border="1px solid #36FFC0" p={pv(8)} borderRadius={pv(8)} pos="relative">
          <Image src={cm} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="green.100" textTransform="uppercase">
                Champion
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              UFC194
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            CONOR MCGREGOR
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>Sat, Feb 11, 2023</Text>
            <Text>$1,826 USD</Text>
          </Flex>
        </GridItem>
        <GridItem border="1px solid #5703FF" p={pv(8)} borderRadius={pv(8)} pos="relative">
          {/* <Flex
            fontSize={pv(12)}
            alignItems="center"
            bg="green.100"
            p={pv(4)}
            lineHeight={pv(22)}
            color="black"
            fontWeight={500}
            pos="absolute"
            borderTopLeftRadius={pv(8)}
            borderBottomRightRadius={pv(8)}
            top={0}
            left={0}
          >
            <Image src={tag} height={pv(14)} mr={pv(4)} />
            Listed
          </Flex> */}
          <Image src={rda} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="#8C53FF" textTransform="uppercase">
                Challenger
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              UFC147
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            RAFAEL DOS ANJOS
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$120 USD</Text>
          </Flex>
        </GridItem>
        <GridItem
          border="1px solid rgba(255, 255, 255, 0.60)"
          p={pv(8)}
          borderRadius={pv(8)}
          pos="relative"
        >
          <Image src={ar} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="#fff" textTransform="uppercase">
                Contender
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              UFC125
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            ALEKSANDAR RAKIC
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$160 USD</Text>
          </Flex>
        </GridItem>
        <GridItem
          border="1px solid rgba(255, 255, 255, 0.60)"
          p={pv(8)}
          borderRadius={pv(8)}
          pos="relative"
        >
          <Image src={ao} h={pv(159)} />
          <Flex alignItems="center" lineHeight={pv(18)} fontSize={pv(12)} mt={pv(4)}>
            <Flex flex={1} alignItems="center">
              <Text fontWeight={600} color="#fff" textTransform="uppercase">
                Contender
              </Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.80)" fontWeight={500}>
              UFC245
            </Text>
          </Flex>
          <Text fontSize={pv(16)} fontWeight={600} lineHeight={pv(20)}>
            ALEX OLIVEIRA
          </Text>
          <Flex mt={pv(5)} fontSize={pv(12)} color="rgba(255, 255, 255, 0.70)" fontWeight={500}>
            <Text flex={1}>As low as</Text>
            <Text>$128 USD</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default React.memo(Index)
