import { Center, Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import React from 'react'
import pv from '@/utils/px2vw'
import { HeartIcon } from '../BaseIcon'
import theme from '@/theme'
import arrowUp from '@/assets/imgs/icons/arrowUp.png'

export interface BigCardInfo {
  haveLike?: boolean
  unit: string
  tokenName: string
  tokenNumber: string
  price: string
  img: string
  type: string
  name: string
  date: string
  addr: string
}

interface IProp extends FlexProps {
  cardInfo: BigCardInfo
}

function Index({ cardInfo, ...props }: IProp) {
  return (
    <Flex
      bgGradient={theme.colors.gradientGreen2}
      borderRadius={pv(8)}
      p={pv(16)}
      flexDir="column"
      w="full"
      boxSizing="border-box"
      {...props}
    >
      {/* img */}
      <Center pos="relative" mb={pv(16)}>
        <Image w="full" h="auto" src={cardInfo.img} borderRadius={pv(4)} />
        {/* Unit */}
        {cardInfo?.tokenNumber ? (
          <Flex
            pos="absolute"
            bgColor="black.200"
            alignItems="center"
            p={`${pv(6)} ${pv(8)}`}
            borderRadius={pv(8)}
            bottom={pv(8)}
            left={pv(8)}
          >
            <Image src={cardInfo?.unit} w={pv(20)} h={pv(20)} mr={pv(4)} borderRadius="50%" />
            <Text textStyle="12">{cardInfo?.price}</Text>
            <Image src={arrowUp} alt="icon" w={pv(9)} h={pv(10)} mx={pv(4)} />
            <Text color="white.50">
              / {cardInfo?.tokenNumber}
              {cardInfo?.tokenName}
            </Text>
          </Flex>
        ) : null}
        {/* Like */}
        {cardInfo?.haveLike && (
          <Center pos="absolute" color="black.100" textStyle="12" bottom={pv(8)} right={pv(8)}>
            <HeartIcon
              bgGradient="none"
              bgColor="black.200"
              w={pv(32)}
              h={pv(32)}
              imgProps={{ w: pv(15), h: pv(13) }}
            />
          </Center>
        )}
      </Center>
      {/* type */}
      <Text color="green.100" textStyle="12" mb={pv(4)}>
        {cardInfo?.type}
      </Text>
      {/* name */}
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        fontWeight="700"
        fontSize={pv(20)}
        lineHeight={pv(26)}
        mb={pv(4)}
      >
        {cardInfo?.name}
      </Text>
      {/* date */}
      <Text color="white.300" textStyle="12" mb={pv(4)}>
        {cardInfo?.date}
      </Text>
      {/* address */}
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        color="white.70"
        textStyle="12"
      >
        {cardInfo?.addr}
      </Text>
    </Flex>
  )
}

export default React.memo(Index)
