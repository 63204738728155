import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'

// 处理日期，获取星期
export function returnWeekName(day: number) {
  switch (day) {
    case 1:
      return 'Mon'
    case 2:
      return 'Tues'
    case 3:
      return 'Wed'
    case 4:
      return 'Thur'
    case 5:
      return 'Fri'
    case 6:
      return 'Sat'
    case 7:
      return 'Sun'
    default:
      ''
  }
}

// 处理日期，返回类似：Oct 01, Tues
export function returnDate(day: number | string) {
  return `${dayjs(day).format('MMM DD')}, ${returnWeekName(dayjs(day).day())}`
}

// 计算法币价格
export function calculatePrice(tokenNumber: string, unitPrice: string) {
  // console.log(tokenNumber, 'tokenNumber')
  // console.log(unitPrice, 'unitPrice')
  const number = new BigNumber(tokenNumber).div(1e18)
  const price = new BigNumber(unitPrice)
  // console.log(number.times(price).toFixed(18), '总价')
  return number.times(price).toFixed(2)
}

// 计算token数量
export function calculateTokenNumber(total: string, unitPrice: string) {
  const totals = new BigNumber(total).times(1e18)
  const unitPrices = new BigNumber(unitPrice).times(1e18)
  return new BigNumber(totals.div(unitPrices).toFixed(18))
}

// 千分位
export const getThousandsValue = (value: number | string) => {
  const val = Number(value)
  return val.toLocaleString('en-us')
}
