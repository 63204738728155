import { Flex, Image } from '@chakra-ui/react'
import React from 'react'
import useSWR from 'swr'
import pv from '@/utils/px2vw'
// import horizontal from '@/assets/imgs/home/horizontal.png'
import { getEventBanner } from '@/apis/explore'
import { useRouter } from 'next/router'

function Index() {
  const router = useRouter()
  const { data: getEventBannerData } = useSWR(getEventBanner.key, () => getEventBanner.fetcher(), {
    revalidateOnFocus: false,
  })

  return (
    <Flex
      overflowX="auto"
      w="full"
      h={pv(160)}
      mb={pv(16)}
      __css={{
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Flex w="max-content">
        {getEventBannerData?.map((item: any, index: number) => (
          <Image
            key={index}
            src={item?.eventBannerImageUrl}
            style={{ objectFit: 'cover' }}
            w={pv(320)}
            h={pv(160)}
            mr={index === getEventBannerData?.length - 1 ? 0 : pv(10)}
            onClick={() => router.push(`/event?type=general&eventId=${item?.eventId}`)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default React.memo(Index)
